import {mapGetters, mapActions, mapMutations} from 'vuex';

export default {
  name: 'page-not-found',
  data() {
    return {}
  },
  components: {},
  created() {},
  mounted() {},
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
    })
  },
  watch: {},
  methods: {
    ...mapActions({}),
    ...mapMutations({}),
    fuion(data) {},
  },
  destroyed() {},
}
